import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/shared/Seo"

const APIDocs = () => (
  <Layout>
    <SEO title="API Docs" />
    <h1>Hi from the second page</h1>
    <p>Welcome to page API Docs</p>
  </Layout>
)

export default APIDocs
